@import "scss/variables";
.vehicleItem :global {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 72px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 499px) {
    flex-direction: column;
  }
  .img-data {
    display: flex;
    flex: 1;
    max-width: calc(100% - 196px);
    &.listing {
      max-width: calc(100% - 140px);
      @media (max-width: 499px) {
        width: 100%;
        max-width: 100%;
      }
    }
    @media (max-width: 499px) {
      width: 100%;
      max-width: 100%;
    }
  }

  .vehicle-img {
    margin: 12px 16px 12px 8px;
    img {
      object-fit: contain;
      min-width: 64px;
      max-width: 64px;
      min-height: 48px;
      max-height: 48px;
      padding: 2px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      box-sizing: border-box;
    }
  }

  .vehicle-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    margin-right: auto;
    padding-right: 12px;
    .vehicle-data-ymmt {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
    }
    .vehicle-data-seller {
      font-size: 15px;
      color: #555555;
      &.dealer-owned {
        font-size: 14px;
        line-height: 17px;
        background: #F7FEE7;
        border: 1px solid #84CC16;
        border-radius: 4px;
        padding: 2px 4px;
        color: $primary-black;
      }
    }
  }
  
  .vehicle-status {
    display: flex;
    align-items: center;
    min-width: 196px;
    max-width: 196px;
    padding: 8px;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    @media (max-width: 499px) {
      flex-direction: column;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      border-left: none;
      width: 100%;
      max-width: 100%;
    }
    .color-line {
      min-width: 4px;
      height: 100%;
      border-radius: 20px;
      margin-right: 16px;
      @media (max-width: 499px) {
        min-width: 100%;
        height: 4px;
        margin-bottom: 8px;
        margin-right: 0px;
      }
    }
  }
  .vehicle-status-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    @media (max-width: 499px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
