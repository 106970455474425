.modal :global {
  .modal-title {
    flex: 1;
  }
  .modal-content {
    min-width: 100%;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1), 0px 5px 15px 1px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    width: fit-content;
    padding: 0px;
  }

  .modal-header {
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    z-index: 999;
    border-bottom: 0px;

    div {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 0px;
    font-size: 16px;
  }

  .modal-footer {
    border-top: 0px;
    flex-wrap: nowrap;
    justify-content: space-between;

    button {
      flex: 1;

      &:last-child {
        font-weight: bold;
      }
    }
  }
}