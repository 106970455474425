@import "scss/variables";

.customSearchableSelect :global {

  .select__control {
    height: 48px;
    padding: 1px;
    border-radius: 6px;
    border-color: #949594;
    margin: 0 15px;

    .select__single-value {
      display: flex;
      align-items: center;
    }

    .select__indicators {
      .select__indicator-separator,
      .select__dropdown-indicator {
        display: none;
      }
      .select__clear-indicator {
        svg {
          cursor: pointer;
          background-color: #D9D8D6;
          border-radius: 35px;
          color: $primary-text-black;
        }
      }
    }
    &.select__control--is-focused {
      background: #FFFFFF;
      border: 2px solid $primary-black;
      box-shadow: 0px 0px 0px 3px $primary-light-yellow;
      padding: 0px;
    }
  }
  .select__menu {
    position: relative;
    box-shadow: none;
    margin-bottom: 0px;
    .select__menu-list {
      min-height: 300px;
          /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f5f6f7;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .select__option {
        cursor: pointer;
        padding-left: 27px;
        padding-right: 27px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &:not(:last-child) {
          border-bottom: 1px solid #F2F2F2;
        }
        &:first-child {
          border-top: 1px solid #F2F2F2;
        }
      }
      .select__group {
        display: flex;
        flex-direction: column;
        padding: 0px;
        .select__group-heading {
          width: 100%;
          padding: 5px 15px;
        }
      }
    }
    .select__option--is-selected {
      background-color: $primary-darker-yellow;
      color: $primary-text-black;
    }
    .select__option--is-focused {
      background-color: $primary-light-yellow;
      color: $primary-text-black;
    }
    .select__option--is-selected.select__option--is-focused  {
      background-color: $primary-darker-yellow;
      color: $primary-text-black;
    }
  }

  .create-label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 20px;
    padding: 2px 6px;
    margin-top: 2px;
    color: $primary-black;
    background: #ECECEC;
    border-radius: 50px;
    font-size: 13px;
    line-height: 16px;
  }
}

.customSelect :global {
  .custom-select__control {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 17px;
    min-height: 48px;
    background: #FFFFFF;
    border: 1px solid #949594;
    box-sizing: border-box;
    border-radius: 6px;
    transition: none;
    &:hover {
      background: #FFFFFF;
      border: 1px solid #949594;
    }
    &.basic-multi-select {
      min-height: 40px;
      height: 40px;
      background-color: #F2F2F2;
      background: rgba(242, 242, 242, 0.9);
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
    &.invalid {
      background: #FFFAF2;
      border: 2px solid #FF4000;
      padding: 1px 16px;
    }
  }
  .custom-select__value-container {
    padding: 0px;
    .custom-select__placeholder {
      color: $placeholder-grey;
    }
    .custom-select__single-value {
      color: $primary-text-black;
    }
  }
  .custom-select__control.custom-select__control--is-focused{
    box-sizing: border-box;
    padding: 1px 16px;
    background: #FFFFFF;
    border: 2px solid $primary-black;
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
    &.invalid {
      background: #FFFAF2;
      border: 2px solid #FF4000;
    }
  }
  .custom-select__control.custom-select__control--is-disabled {
    background-color: #FAFAFA;
    color: #181818;
    opacity: 0.5;
  }
  .custom-select__indicators {
    .custom-select__indicator-separator {
      display: none;
    }
    .search.custom-select__dropdown-indicator {
      display: none;
    }
    .custom-select__indicator {
      cursor: pointer;
      padding-right: 0px;
      svg {
        color: $primary-text-black;
      }
    }
  }
  .custom-select__menu {
    z-index: 1000 !important;
    .custom-select__menu-list {
          /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f5f6f7;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      > * {
        cursor: pointer;
      }

      > :not(:last-child) {
        border-bottom: 1px solid #F2F2F2;
      }
    }
    .custom-select__option--is-disabled.custom-select__option--is-focused {
      background-color: transparent;
      color: hsl(0, 0%, 80%);
      font-size: inherit;
      padding: 8px 12px;
    }
    .custom-select__option--is-focused {
      background-color: $primary-light-yellow;
      color: $primary-text-black;
    }
    .custom-select__option--is-selected {
      background-color: $primary-darker-yellow;
      color: $primary-text-black;
    }
    .custom-select__option:active {
      background-color: $primary-light-yellow;
      color: $primary-text-black;
    }
  }
}
