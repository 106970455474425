@import "scss/variables";
@import "scss/mixins";

.textAreaFormControl :global {
  position: relative;
  .character-counter {
    @include flexConfig(row, center, center);
    position: absolute;
    right: 20px;
    bottom: -12px;
    font-size: 13px;
    padding: 2px 4px;
    border-radius: 8px !important;
    z-index: 999;
    min-width: 80px;
    height: 27px;
    line-height: 100%;
    text-align: center;
    border: 1px solid #949594;
    margin-left: 0 !important;
    box-sizing: border-box;
    background: #FFFFFF;
  }
  .form-control {
    padding: 7px 13px 20px !important;
    &:focus {
      padding: 6px 12px 21px !important;
    }
    &.is-invalid {
      padding: 6px 12px 21px !important;

    }
  }
  .form-control:focus + .character-counter {
    border: 2px solid $primary-black;
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
    padding: 1px 3px;
  }

  .form-control.is-invalid + .character-counter {
    border: 2px solid #FF4000;
    background: #FFFAF2;
    padding: 1px 3px;
  }

  .form-control.is-invalid:focus + .character-counter {
    border: 2px solid #FF4000;
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
    padding: 1px 3px;
  }

  .form-control:disabled + .character-counter {
    background-color: #F4F4F5;
    opacity: 1;
  }
}