@import "scss/variables";

.agreement :global {
  .consumer-warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FAFAFA;
    border: 2px solid rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 12px;
    margin: 1.5rem 0;

    button {
      align-self: center;
      width: fit-content;
    }
    svg {
      margin-bottom: 3px;
    }
  }

  .signature-submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    margin: 1.5rem 0;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 1px 0px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
  }

  .card-header {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    padding-left: 24px;
    padding-right: 24px;
    .card-header-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .list-group-item {
    padding: 16px 24px;
  }

  .send-contact {
    margin-bottom: 1.5rem;

    .send-agreement-btn {
      height: 40px;
      font-weight: 500;
      color: $primary-text-black;
      background: $primary-light-yellow;
      border: 1px solid $primary-light-yellow;
      &:focus {
        border: 1px solid $primary-light-yellow;
        box-shadow: none;
      }
    }
  }

  .section-title {
    font-weight: 500;
    font-size: 16px;
    margin-right: auto;

    &.has-amendment {
      border-bottom: 1px solid $red;
    }
  }

  .accordion-body {
    padding: 24px;
  }

  .contract-list-item {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .accepted-title {
    position: relative;

    strong {
      background-color: #fff;
      padding: 0 10px;
      margin-left: -10px;
      display: inline-block;
      position: relative;
    }

    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: calc(100% + 48px);
      left: -24px;
      top: 10px;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .signature-section {
    margin: -24px;

    .signature-pad-wrapper {
      padding: 24px;
    }

    ul {
      li {
        padding: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);

        &:last-child {
          border-bottom: 0;
        }

        img {
          max-width: 200px;
        }
      }
    }
  }

  .stage-view {
    @media (min-width: 768px) and (max-width: 991px) {
      .ejs-table {
        font-size: 12px;
      }
    }
  }
}
