.scanner :global {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;

  @media (max-width: 1000px) {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 1000;
    left: 0;
  }


  .dce-sel-camera,
  .dce-sel-resolution,
  .dbr-msg-poweredby {
    display: none!important;
  }
  .dce-btn-close {
    display: flex;
    align-items: center;
    height: 24px;
    border: none;
    background: white;
  }
}

.landscapeWarning :global {
  background-color: #ffffff;
  z-index: 90000;
  padding: 30px;
  display: none;
  position: fixed;
  top: 46%;
  opacity: 0.9;

  @media (min-width: 1000px) {
    display: none;
  }

  @media screen and (orientation:portrait) {
    //display: block;
  }
}
