.status :global {

  .card-header {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    padding-left: 24px;
    padding-right: 24px;
    .card-header-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .list-group-item {
    padding: 16px 24px;
  }

  .status-sent {
    background-color: #FFDE68;
    display: inline-block;
    padding: 0px 8px;
    border-radius: 15px;
  }
  .step-progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .progress-text {
      font-size: 15px;
      line-height: 18px;
      color: #0B3317;
      margin-bottom: 2px;
    }
    .progress-indicator {
      display: block;
      position: relative;
      min-width: 100px;
      max-width: 100px;
      min-height: 4px;
      max-height: 4px;
      background: #E6E6E6;
      border-radius: 10px;
      span {
        position: absolute;
        height: 100%;
        background: #00DD42;
        border-radius: 10px;
      }
    }
  }
}
