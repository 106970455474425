@import "scss/variables";


.accordion :global {

  .accordion-item {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0 !important;
    background-color: #ECECEC;
    border: none;
  }

  .accordion-custom-toggle {
    padding: 6px 8px;
    margin-right: 4px;
    box-sizing: border-box;
    border: 0px;
    &:focus {
      padding: 4px 6px;
      color: $primary-text-black;
      background: $secondary-light-color;
      border: 2px solid $primary-black;
      box-shadow: 0px 0px 0px 3px $primary-light-yellow;
    }
    > span {
      display: block;
      transition: all 0.2s;
    }

    &.is-opened {
      > span {
        transform: rotate(90deg);
      }
    }
  }

  .accordion-parent-header {
    display: flex;
    gap: 8px;
  }

  .accordion-heading {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: $primary-text-black;
    margin-right: auto;
  }

  .accordion-header {
    .accordion-button {
      padding: 14px 9px;
      background-color: #ECECEC;
      color: $primary-black;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      border-bottom: 1px solid #DCDDDE;

      &::before {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M6%2012L10%208L6%204%22%20stroke%3D%22%23181818%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        background-repeat: no-repeat;
        background-size: 16px;
        transition: transform 0.2s ease-in-out;
      }

      &::after {
        content: none;
      }

      &:focus {
        border-color: none;
        box-shadow: none
      }
    }

    .accordion-button:not(.collapsed) {
      color: $primary-black;
      box-shadow: none;
      border-bottom: none !important;
    }

    .accordion-button:not(.collapsed)::before {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M6%2012L10%208L6%204%22%20stroke%3D%22%23181818%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      transform: rotate(90deg);
    }
  }

  .accordion-body {
    padding: 0;
    overflow: hidden;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.03);
  }

  .accordion-collapse {
    background-color: #ECECEC;
    padding: 5px 0;
  }
}

;
