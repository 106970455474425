@import "scss/variables";

.header :global {
  nav {
    background: $primary-black;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .signinButton {
    background-color: $primary-light-yellow;
    padding: 6px 35px;
    color: #181818;
    font-weight: 600;
    font-size: 18px;
    border-radius: 120px;

    @media (max-width: 1200px) {
      padding: 6px 20px;
      font-size: 16px;
    }

    @media (max-width: 440px) {
      padding: 2px 8px;
      font-size: 12px;
    }
  }

  .dropdown {
    cursor: pointer;
    width: 32px;
    height: 32px;
    &.show {
      background: #FFFFFF;
      border-radius: 7px;
      path {
        stroke: #444444;
      }
    }
  }
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
  .dropdown-item {
    width: 100%;
    color: $primary-black !important;
    &:hover, &:focus {
      background-color: $primary-light-yellow;
    }
    &:active {
      background-color: transparent;
    }
    &:active:hover {
      background-color: $primary-light-yellow;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .navbar-brand {
    display: flex;

    .dealer-logo {
      flex-direction: column;
      font-size: 11px;
      color: #fff !important;
      font-weight: 600;

      span {
        line-height: 10px;
        padding-top: 3px;
      }
    }
  }

  .navbar-dark .navbar-toggler {
    color: #ffffff;
    border-color: #ffffff;
    padding: 3px 6px;
    &.consumer-toggle {
      border: 0;
      padding: .25rem .75rem;
      margin-right: 12px;
    }
    svg, path {
      fill: #FFFFFF !important;
    }
  }

  .navbar-toggler:focus {
    box-shadow: 0 0 0 3px $primary-light-yellow;;
  }

  .navbar-nav {
    margin: 0 !important;
    width: 100%;

    .dropdown-menu {
      a {
        color: $primary-black;
        padding: 0.25rem 1rem;
        font-weight: 400;
      }
    }

    .navi-link {
      padding: 8px 30px;
      font-weight: 800;

      @media (max-width: 1199px) {
        padding: 8px 16px;
      }
    }
  }

  a {
    text-decoration: none;
    width: fit-content;

    &:hover {
      color: $primary-light-yellow;
    }
  }

  .inactive-nav {
    color: #ffffff;
  }

  .active-nav {
    color: $primary-darker-yellow;

    &:hover {
      color: $primary-darker-yellow;
    }
  }

  .nav-main {
    gap: 16px;
    display: flex;
    align-items: center;

    @media (max-width: 350px) {
      gap: 0px;
    }

    @media (max-width: 567px) {
      gap: 10px;
    }
  }
}
