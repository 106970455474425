.emptyState {
  :global {
    width: 100%;
    div {
      display: flex;
      width: 100%;
      min-height: 180px;
      padding: 60px;
      background: rgba(0, 0, 0, 0.01);
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
    }
  }
}
