.fieldsArray :global {
  margin-bottom: 1.5rem;
  .ownerships {
    position: relative;
    background-color: #e5e5e5;
    padding: 10px 0px;
    margin-top: 1.5rem;
    margin-right: calc(-.7 * var(--bs-gutter-x));
    margin-left: calc(-.7 * var(--bs-gutter-x));
    &:before {
      content: "";
      position:absolute;
      top:-8px;
      left:0;
      height:8px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: 0 4px 0 0 #e5e5e5;
    }
    &::after {
      content: "";
      position:absolute;
      top:58px;
      left:0;
      height:8px;
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.03);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      box-shadow: 0 -4px 0 0 #e5e5e5;
    }
    button {
      color: #222;
      white-space: nowrap;
      font-size: 14px;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.03)
    }

    .btn-check:checked + .btn-secondary:focus, 
    .btn-check:active + .btn-secondary:focus, 
    .btn-secondary:active:focus, 
    .btn-secondary.active:focus, 
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.03);
    }
  }

  .liens {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    button {
      padding: 1.5rem 0 0 0 !important;
      color: #222;
      white-space: nowrap;
      background-color: white;
      border-color: white;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      &:active,&:focus {
        box-shadow: none;
      }
    }
  }

}
