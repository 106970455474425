@import "scss/variables";
.inputLabelWithValidation :global {
  .error-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    .error-label {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #591700;

      .label {
        flex: 1;
      }
    }
    .error-message {
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: #591700
    }
  }
  .label-with-badge {
    width: 100%;
    display: flex;
    align-items: center;
    .label {
      margin-right: auto;
      padding-right: 4px;
    }
    .badge {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 2px 6px;
      background: #F2F2F2;
      border-radius: 50px;
      font-weight: 500;
      font-size: 13px;
      line-height: 130%;
      color: $primary-text-black;
      &::before {
        content: 'Optional';
      }
      &.required {
        background: $primary-light-yellow;
        color: $primary-text-black;
        &::before {
          content: 'Required';
        }
      }
    }
  }
  .helper-text {
    display: block;
    margin-bottom: 8px;
    margin-top: 2px;
    font-size: 14px;
    line-height: 100%;
    --bs-text-opacity: 1;
    color: #6c757d !important;
  }
}