@import "scss/variables";

.textInput :global {
  .numNoArrows {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
}

.checkbox :global {
  .list-group-item {
    border-color: #949594;
  }
  .invalid {
    background: #FFFAF2;
    border-color: #FF4000;
    border-width: 2px;
  }

  .invalid + .invalid {
    border-top-width: 0;
  }
}

.yesNo :global {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  .form-check-input {
    width: 96px;
    height: 48px;
    line-height: 43px;
    background: linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%);
    border: 1px solid #BEBFBE;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    text-align: center;
    color: $primary-text-black;
    cursor: pointer;
    margin: 0px;
    &:checked,
    &:active {
      color: white;
      background-color: $primary-text-black;
      border-color: $primary-text-black;
      background-image: none;
    }
    &:focus {
      background: #FAFAFA;
      color: $primary-text-black;
      border: 2px solid #FFFFFF;
      box-shadow: 0px 0px 0px 2px $primary-black, 0px 0px 0px 5px $primary-light-yellow;
      border-radius: 5px !important;
    }
    &:checked:focus {
      color: white;
      background-color: $primary-text-black;
      border-color: $primary-text-black;
    }
  }
  .yes {
    .form-check-input::before{
      content: 'Yes';
    }
  }
  .no {
    .form-check-input::before{
      content: 'No';
    }
  }
}

.colorPicker :global {
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    background: #FFFFFF;
    border-radius: 6px;
    border: 2px solid transparent;
    &.is-invalid {
      border: 2px solid #FF4000;
    }
    .color-picker-item {
      position: relative;
      flex: 1 0 33%;;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px;
      height: 100px;
      box-shadow: 
      1px 0 0 0 #D9D9D9, 
      0 1px 0 0 #D9D9D9, 
      1px 1px 0 0 #D9D9D9,   /* Just to fix the corner */
      1px 0 0 0 #D9D9D9 inset, 
      0 1px 0 0 #D9D9D9 inset;
      &.is-invalid {
        box-shadow: 
        1px 0 0 0 #FF4000, 
        0 1px 0 0 #FF4000, 
        1px 1px 0 0 #D9D9D9,   /* Just to fix the corner */
        1px 0 0 0 #D9D9D9 inset, 
        0 1px 0 0 #D9D9D9 inset;
      }
      @media (max-width: 365px) {
        flex: 1 0 50%;
        &:nth-child(1) {
          border-top-left-radius: 6px;
        }
        &:nth-child(2) {
          border-top-right-radius: 6px;
        }
        &:last-child {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
      @media (min-width: 366px) and (max-width: 520px) {
        flex: 1 0 33%;
        &:nth-child(1) {
          border-top-left-radius: 6px;
        }
        &:nth-child(3) {
          border-top-right-radius: 6px;
        }
        &:nth-child(13) {
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          border-bottom-right-radius: 6px;
        }
      }
      @media (min-width: 521px) and (max-width: 767px) {
        flex: 1 0 20%;
        &:nth-child(1) {
          border-top-left-radius: 6px;
        }
        &:nth-child(5) {
          border-top-right-radius: 6px;
        }
        &:nth-child(11) {
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          border-bottom-right-radius: 6px;
        }
      }
      @media (min-width: 768px) and (max-width: 991px) {
        flex: 1 0 33%;
        &:nth-child(1) {
          border-top-left-radius: 6px;
        }
        &:nth-child(3) {
          border-top-right-radius: 6px;
        }
        &:nth-child(13) {
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          border-bottom-right-radius: 6px;
        }
      }
      @media (min-width: 992px) {
        flex: 1 0 20%;
        &:nth-child(1) {
          border-top-left-radius: 6px;
        }
        &:nth-child(5) {
          border-top-right-radius: 6px;
        }
        &:nth-child(11) {
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          border-bottom-right-radius: 6px;
        }
      }
      .form-check-input {
        margin: 0px;
        width: 100%;
        height: 100%;
        background-image: none;
        border-radius: 6px;
        border: none;
        box-shadow: none;
        cursor: pointer;
        &:hover {
          background: #F2F2F2;
          border: none;
        }
        &:checked, &:checked:hover {
          background: $primary-text-black;
          border: none;
        }
        &:checked + .form-check-label, &:checked:hover + .form-check-label {
          color: #FFFFFF;
        }
        &:checked + .form-check-label .color-circle, &:checked:hover + .form-check-label .color-circle {
          border: 2px solid #FFFFFF;
        }
        &:focus {
          background: #FFFFFF;
          box-shadow: inset 0px 0px 0px 2px $primary-light-yellow, inset 0px 0px 0px 3px #FFFFFF;
          border-radius: 6px;
          border: none;
        }
      
        &:checked:focus {
          background: $primary-text-black;
          box-shadow: inset 0px 0px 0px 2px #F9DC4B, inset 0px 0px 0px 3px #FFFFFF;
          border-radius: 6px;
          border: none;
        }
      }
      .form-check-label {
        position: absolute;
        width: fit-content;
        height: fit-content;
        color: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        .color-circle {
          height: 24px;
          width: 24px;
          border-radius: 32px;
          z-index: 100;
          &.white {
            border: 2px solid rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }

}
