@import "scss/variables";

.signPad :global {
  position: relative;

  .wrapper {
    position: relative;
    max-width: 400px;
    height: 260px;
    margin: auto;
    @media (max-width: 500px) {
      max-width: 300px;
    }

    canvas {
      background: #FAFAFA url('../../public/assets/sign-text.png') no-repeat center;
      background-size: 85px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      border-radius: 7px;
      width: 100%;
      height: 100%;
    }
  }

  .clear-button {
    position: absolute;
    top: 3px;
    right: 3px;
  }
}
