@import "./scss/reset";
@import "./scss/custom";
@import "./scss/variables";

@-moz-document url-prefix() {
  html {
    scroll-behavior: auto !important;
  }
}

.fade:not(.show) {
  visibility: hidden;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  color: $primary-text-black;
  background-color: #ECECEC;
  font-family: 'Poppins', sans-serif;
}

a {
  color: $primary-text-black;

  &:hover {
    color: $primary-text-black;
  }
}

.card.bg-yellow {
  background: $primary-light-yellow;
}

.green-text {
  color: $primary-green;
  font-weight: bold;
  font-size: 24px;
}

textarea {
  overflow: hidden;
}

.title {
  font-weight: bold;
}

.font-20 {
  font-size: 20px;
}

strong {
  font-weight: 600;

  &.bolder {
    font-weight: 800;
  }
}

.badge.bg-secondary {
  background-color: $primary-gray !important;
  font-weight: 400;
}

.no-underline {
  text-decoration: none !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

hr + hr {
  display: none;
}


/*--------------list-group-------------*/

.list-group-item {
  color: $primary-text-black;
  background: #FFFFFF;

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &.invalid-field {
    background: #FFFAF2;
    border-color: #FF4000;
    border-width: 2px;

    &:not(:last-child) {
      border-bottom: none;
    }
  }

  &.invalid-field + &.invalid-field {
    border-top-width: 0;
  }
}

.no-borders .list-group-item:not(:last-child) {
  border-bottom: none;
}

/*--------------checkbox-radio-------------*/
.form-check {
  margin-bottom: 0;
  padding-left: 0;
}

.form-check-input[type="radio"] {
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  border: 1px solid #949594;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.03);
  border-radius: 25px;
  margin-left: 10px;
  margin-top: 8px;

  &:checked {
    background: #FFFFFF;
    border: 3px solid $primary-black;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23222'/%3e%3c/svg%3e");
  }

  &:focus {
    background-color: #FFFFFF;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 0px 0px 2px $primary-black, 0px 0px 0px 5px $primary-light-yellow;
    border-radius: 45px;
    background-image: none;
  }

  &:checked:focus {
    background: #FFFFFF;
    border: 3px solid $primary-black;
    box-shadow: 0px 0px 0px 1px #fff, 0px 0px 0px 3px $primary-black, 0px 0px 0px 5px $primary-light-yellow;
    border-radius: 35px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23222'/%3e%3c/svg%3e");
  }

  &.is-invalid {
    background: #FFFAF2;
    border: 2px solid #FF4000;
    border-radius: 25px;
  }
}

.form-check-input[type="checkbox"],
.form-check input[type="checkbox"] {
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  border: 1px solid #949594;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 8px;

  &:checked {
    background: $primary-black;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.03);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e")
  }

  &:focus {
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 0px 0px 2px $primary-black, 0px 0px 0px 5px $primary-light-yellow;
  }

  &:checked:focus {
    background: $primary-black;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 0px 0px 2px $primary-black, 0px 0px 0px 5px $primary-light-yellow;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e")
  }

  &.is-invalid {
    background: #FFFAF2;
    border: 2px solid #FF4000;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.03);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.form-check-label {
  display: block;
  padding: 5px 5px 5px 40px;
  cursor: pointer;
}

/*--------------Input-------------*/

.navi-link.disabled {
  cursor: crosshair;
  opacity: 0.5;
}

.input-group {
  > :first-child,
  > :nth-child(2) {
    border: 1px solid #949594;
  }

  svg {
    border: none !important;
  }

  .form-control {
    height: 48px;
    padding: calc(0.375rem + 1px) calc(0.75rem + 1px);
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
    color: $primary-text-black;

    &::placeholder {
      color: $placeholder-grey;
    }

    &:disabled,
    &:read-only {
      border: 1px solid #E4E4E7;
      background-color: #F4F4F5;
      opacity: 1;
    }
  }

  .input-group-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 47px;
    background: #FFFFFF;
    border-radius: 6px;
    color: $primary-text-black;
    box-sizing: border-box;
  }

  .form-control.is-invalid + .input-group-text {
    background: #FFFAF2;
    border: 2px solid #FF4000;
  }

  .form-control:-internal-autofill-selected + .input-group-text {
    background-color: rgb(232, 240, 254)
  }

  &.input-with-suffix {
    .input-group-text {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
      margin-left: -2px !important;
    }

    &.password {
      .form-control {
        border-right: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      .input-group-text {
        border-left: 0;
        cursor: pointer;
      }
    }

    .form-control {
      border-right: 1px solid #D9D9D9;

      &:focus {
        padding: 0.375rem calc(0.75rem + 1px) 0.375rem 0.75rem;
        border: 2px solid $primary-black;
        border-right: 1px solid #D9D9D9;
        box-shadow: 0px 0px 0px 3px $primary-light-yellow;
        clip-path: inset(-3px 0px -3px -3px)
      }

      &.is-invalid {
        padding: 0.375rem calc(0.75rem + 1px) 0.375rem 0.75rem;
        border: 2px solid #FF4000;
        border-right: 1px solid #D9D9D9;
        background: #FFFAF2;
      }
    }

    .form-control:focus + .input-group-text {
      border: 2px solid $primary-black;
      border-left-color: transparent;
      box-shadow: 0px 0px 0px 3px $primary-light-yellow;
      clip-path: inset(-3px -3px -3px 0px)
    }

    .form-control.is-invalid:focus + .input-group-text {
      border: 2px solid #FF4000;
      border-left-color: transparent;
      box-shadow: 0px 0px 0px 3px $primary-light-yellow;
      clip-path: inset(-3px -3px -3px 0px)
    }

    .form-control:disabled + .input-group-text {
      border: 1px solid #E4E4E7;
      background-color: #F4F4F5;
      opacity: 1;
    }
  }

  &.input-with-prefix {
    flex-direction: row-reverse;

    .input-group-text {
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      margin-right: -2px !important;
      margin-left: 0px !important;
    }

    &.address-input {
      .input-autocomplete.form-control {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-right: none;
      }

      .clear-close.input-group-text {
        border-color: #949594;
        cursor: pointer;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
      }
    }

    .form-control {
      border-left: 1px solid #D9D9D9;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;

      &:focus {
        padding: 0.375rem 0.75rem 0.375rem calc(0.75rem + 1px);
        border: 2px solid $primary-black;
        border-left: 1px solid #D9D9D9;
        box-shadow: 0px 0px 0px 3px $primary-light-yellow;
        clip-path: inset(-3px -3px -3px 0px)
      }

      &.is-invalid {
        padding: 0.375rem 0.75rem 0.375rem calc(0.75rem + 1px);
        border: 2px solid #FF4000;
        border-left: 1px solid #D9D9D9;
        background: #FFFAF2;
      }
    }

    .form-control:focus + .input-group-text {
      border: 2px solid $primary-black;
      box-shadow: 0px 0px 0px 3px $primary-light-yellow;
      clip-path: inset(-3px 0px -3px -3px)
    }

    .form-control.is-invalid:focus + .input-group-text {
      border: 2px solid #FF4000;
      // border-right-color: transparent;
      box-shadow: 0px 0px 0px 3px $primary-light-yellow;
      clip-path: inset(-3px 0px -3px -3px)
    }

    .form-control:disabled + .input-group-text {
      border: 1px solid #E4E4E7;
      background-color: #F4F4F5;
      opacity: 1;
    }
  }

  &.input-with-prefix-and-suffix {
    .input-group-text {
      &.prefix {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-right: none !important;
        margin-left: 0px !important;
        padding: 1px;
        clip-path: inset(-3px 2px -3px -3px);
      }

      &.suffix {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-left: none !important;
        padding: 1px;
        clip-path: inset(-3px -3px -3px 1px);
      }

      z-index: 20 !important;
      padding: 2px;
    }

    &.address-input {
      .input-autocomplete.form-control {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-right: none;
        border-left: none;
      }

      .clear-close.input-group-text {
        border-color: #949594;
        cursor: pointer;
      }
    }

    .form-control {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      clip-path: inset(-3px 2px);
      margin: 0px -5px;

      &:focus {
        padding: 0.375rem calc(0.75rem + 1px);
        border: 2px solid $primary-black;
        box-shadow: 0px 0px 0px 3px $primary-light-yellow;
      }

      &.is-invalid {
        padding: 0.375rem calc(0.75rem + 1px);
        border: 2px solid #FF4000;
        background: #FFFAF2;
      }
    }

    .form-control:focus ~ .input-group-text {
      &.prefix {
        padding: 0px 1px 0px 0px;
      }

      &.suffix {
        padding: 0px 0px 0px 1px;
      }

      border: 2px solid $primary-black;
      box-shadow: 0px 0px 0px 3px $primary-light-yellow;
    }

    .form-control.is-invalid ~ .input-group-text {
      &.prefix {
        padding: 0px 1px 0px 0px;
      }

      &.suffix {
        padding: 0px 0px 0px 1px;
      }

      border: 2px solid #FF4000;
      background: #FFFAF2;
    }

    .form-control.is-invalid:focus ~ .input-group-text {
      &.prefix {
        padding: 0px 1px 0px 0px;
      }

      &.suffix {
        padding: 0px 0px 0px 1px;
      }

      border: 2px solid #FF4000;
      box-shadow: 0px 0px 0px 3px $primary-light-yellow;
    }

    .form-control:disabled ~ .input-group-text {
      border: 1px solid #E4E4E7;
      background-color: #F4F4F5;
      opacity: 1;
    }
  }

  &.plain-control {

    .form-control {
      border-radius: 6px !important;
      border: 1px solid #949594;
      padding: calc(0.375rem + 1px) calc(0.75rem + 1px);

      &:focus {
        padding: 0.375rem 0.75rem;
        border: 2px solid $primary-black;
        box-shadow: 0px 0px 0px 3px $primary-light-yellow;
      }

      &.is-invalid {
        padding: 0.375rem 0.75rem;
        background: #FFFAF2;
        border: 2px solid #FF4000;
      }
    }
  }
}

input[type=number] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input[type="time"] {
  width: fit-content;

  &::-webkit-datetime-edit-ampm-field {
    text-transform: lowercase;
    margin-left: -7px;
  }

  &::-webkit-calendar-picker-indicator {
    display: inline-block;
    cursor: pointer;
  }
}

input[placeholder] {
  text-overflow: ellipsis !important;
}

/*--------------Badges-------------*/

.standartBadge {
  padding: 0.25rem 0.5rem;
  background: #ECECEC;
  border-radius: 40px;
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
}

.custom-badge {
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;

  &.green {
    background-color: #BBF7D0;
    color: #14532D;
    border: 1px solid #86EFAC;
  }

  &.purple {
    background-color: #E9D5FF;
    color: #581C87;
    border: 1px solid #D8B4FE;
  }

  &.gray {
    background-color: #E4E4E7;
    color: #18181B;
    border: 1px solid #D4D4D8;
  }

  &.orange {
    background-color: #FED7AA;
    color: #7C2D12;
    border: 1px solid #FDBA74;
  }

  &.red {
    background-color: #CC0000;
    color: #fff;
    border: 1px solid rgba(204, 0, 0, 0.15);
  }
}

/*--------------Buttons-------------*/

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
:not(.btn-check) + .btn:active:focus-visible,
.btn:first-child:active,
.btn:first-child:active:focus-visible,
.btn:focus-visible,
.btn.active,
.btn.show {
  color: unset;
  background-color: unset;
  border-color: unset;
  box-shadow: unset;
}

.link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-text-black;
  width: fit-content;
  box-shadow: none;
  box-sizing: border-box;
  border: 2px solid transparent;
  padding: 0px 1px;
  border-radius: 4px;

  &:hover, &:active {
    color: $primary-text-black;
  }

  &:focus {
    border: 2px solid $primary-black;
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
    color: $primary-text-black;
  }

  &:active:focus {
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
  }
}

.panel-button {
  background: #FFFFFF;
  border: 1px solid #F9DC4B;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 15px;
  color: #222222;

  &:hover {
    background: #FFFFFF;
    color: #222222;
    border: 1px solid #F9DC4B;
  }
}

.submitButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  white-space: nowrap;

  height: 48px;

  background: $primary-text-black;
  border-color: $primary-text-black;
  color: #FFF;
  border-radius: 8px;

  &:hover,
  &:disabled {
    color: #FFF;
    background: $primary-text-black;
    border-color: $primary-text-black;
  }

  &:focus,
  &:active,
  &:active:focus {
    background: $primary-text-black;
    color: #FFF;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 0px 0px 2px $primary-black, 0px 0px 0px 5px $primary-light-yellow;
    border-radius: 5px;
  }
}

.destructiveButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  white-space: nowrap;

  height: 40px;
  width: fit-content;

  background: $secondary-light-color;
  color: $primary-text-black;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  &.red {
    background: #CC0000;
    color: #FFFFFF;

    &:hover {
      background: #CC0000;
      color: #FFFFFF;
      border: 1px solid #D9D9D9;
    }
  }

  &:disabled {
    background: $secondary-light-color;
    color: $primary-text-black;
    border: 1px solid #D9D9D9;
    pointer-events: none;
    opacity: 0.65;
  }

  &:hover {
    background: $secondary-light-color;
    color: $primary-text-black;
    border: 1px solid #D9D9D9;
  }

  &:active {
    color: #FFF;
    background: #EE0000;
    border: none;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &:focus {
    color: $primary-text-black;
    background: $secondary-light-color;
    border: 2px solid $primary-black;
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
  }

  &:active:focus {
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
  }
}

.importantButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  white-space: nowrap;

  height: 40px;

  background: $secondary-light-color;
  color: $primary-text-black;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  &:disabled {
    background: $secondary-light-color;
    color: $primary-text-black;
    border: 1px solid #D9D9D9;
    pointer-events: none;
    opacity: 0.65;
  }

  &:hover {
    color: $primary-text-black;
    background: $primary-light-yellow;
    border: 1px solid #F4C00F;
    box-shadow: 0px 0px 0px 3px rgba(34, 34, 34, 0.15);
  }

  &:active {
    color: #FFF;
    background: #555555;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &:focus {
    color: $primary-text-black;
    background: $secondary-light-color;
    border: 2px solid $primary-black;
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
  }

  &:active:focus {
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
  }
}

.font-32 {
  font-size: 32px;
}

.font-36 {
  font-size: 36px;
}

.card-standard {
  background: $secondary-light-color !important;
  border: 1px solid $primary-color !important;
}

.card-dark {
  padding: 24px;
  background: $primary-black !important;
  color: white;

  a {
    color: white;
  }
}

.card-yellow {
  padding: 24px;
  background: $primary-light-yellow !important;
}

.empty-list {
  background: #FAFAFA;
  border: 1px solid #DBDBDB;
  border-radius: 7px;
  padding: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  min-height: 200px;
}

.btn-standard,
.standardButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  white-space: nowrap;

  height: 40px;
  width: fit-content;

  background: $secondary-light-color;
  color: $primary-text-black;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  &.go-to-prev-page {
    height: 32px;
    background: #E5E5E5;
    border: 1px solid #D9D9D9;
    color: $primary-black;
    @media (max-width: 767px) {
      width: 100%;
      height: 40px;
    }
  }

  &.publish {
    background: $primary-text-black;
    border-color: $primary-text-black;
    color: #FFF;
  }

  &.get-started,
  &.get-started:hover {
    width: 100%;
    margin-bottom: 8px;
    color: $primary-light-yellow;
    background-color: $primary-black;
    font-weight: bold;
    font-size: 20px;
  }

  &.btn-dark {
    color: $secondary-light-color;
    background: $primary-text-black;

    &:disabled {
      color: $secondary-light-color;
      background: $primary-text-black;
      opacity: 1;
    }
  }

  &.intent-view {
    height: 48px;
    font-weight: 800;
    font-size: 20px;
    background-color: $primary-black;
    color: $primary-light-yellow;
  }

  &.intent-share {
    height: 48px;
    font-weight: 800;
    font-size: 20px;
    background: #FAFAFA;
    color: $primary-black;
  }

  &.location-display {
    gap: 8px;
    width: fit-content;
    max-width: 100%;
    justify-content: space-between;
    background: rgba(242, 242, 242, 0.9);
    border: 1px solid rgba(0, 0, 0, 0.1);

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.light-yellow {
    background-color: $primary-light-yellow !important;
    border: 1px solid #F4C00F;
  }

  &.grey {
    height: 32px;
    background: transparent;
    border: 1px solid #D9D9D9;
    color: $primary-black;
  }

  &.appointment-action {
    background-color: #FFF;
    border-radius: 6px;
    text-decoration: none;
    padding: 4px 8px;
    border: 1px solid #d9d9d9;
    height: 34px;
    box-sizing: border-box;

    &:focus {
      padding: 3px 7px;
    }
  }

  &:disabled {
    background: $secondary-light-color;
    color: $primary-text-black;
    border: 1px solid #D9D9D9;
    pointer-events: none;
    opacity: 0.65;
  }

  &:hover {
    color: $primary-text-black;
    background: $primary-light-yellow;
    border: 1px solid #F4C00F;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);;
    box-sizing: border-box;
  }

  &:active {
    color: $primary-text-black;
    background: #E6E6E6;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  &:focus {
    padding: 5px 11px;
    color: $primary-text-black;
    background: $secondary-light-color;
    border: 2px solid $primary-black;
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
  }

  &:active:focus {
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
  }

  &.icon {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    transition: none;

    &:hover {
      background-color: $white;

      svg path {
        fill: $primary-black;
      }
    }
  }
}

.warningButton {

  background: #CC0000;
  color: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  &:hover {
    background: #CC0000;
    color: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }

  &:focus {
    background: #CC0000;
    color: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }

  &:active:focus {
    background: #CC0000;
    color: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }

}

.standardIconButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  white-space: nowrap;

  position: static;
  width: 40px;
  height: 40px;

  color: $primary-text-black;
  background: $secondary-light-color;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  &.grey {
    height: 32px;
    background: transparent;
    border: 1px solid #D9D9D9;
    color: $primary-black;
    padding: 5px 11px;
  }

  &.checkAll {
    height: 32px;
    width: 32px;
    background: transparent;
    border: 1px solid #D9D9D9;
  }

  &.dark {
    color: $secondary-light-color;
    background: $primary-text-black;

    svg {
      fill: #ffffff;
    }

    &:hover {
      svg {
        fill: $primary-text-black;
      }
    }

    &:focus {
      color: $secondary-light-color;
      background: $primary-text-black;

      svg {
        fill: #ffffff;
      }
    }
  }

  &:disabled {
    background: $secondary-light-color;
    color: $primary-text-black;
    border: 1px solid #D9D9D9;
    pointer-events: none;
    opacity: 0.65;
  }

  &:hover {
    color: $primary-text-black;
    background: $primary-light-yellow;
    border: 1px solid $primary-darker-yellow;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }

  &:active {
    color: $primary-text-black;
    background: #E6E6E6;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }

  &:focus {
    padding: 7px 11px;
    color: $primary-text-black;
    background: $secondary-light-color;
    border: 2px solid $primary-black;
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
    box-sizing: border-box;
  }

  &:active:focus {
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
  }
}

.button-with-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 40px;
  height: 32px;
  background: $secondary-light-color;
  color: $primary-black;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin: 0 8px;

  &:disabled {
    background: $secondary-light-color;
    color: $primary-text-black;
    border: 1px solid #D9D9D9;
    pointer-events: none;
    opacity: 0.65;
  }

  &:hover {
    color: $primary-text-black;
    background: $primary-light-yellow;
    border: 1px solid $primary-darker-yellow;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }

  &:active {
    color: $primary-text-black;
    background: #E6E6E6;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }

  &:focus {
    padding: 7px 11px;
    color: $primary-text-black;
    background: $secondary-light-color;
    border: 2px solid $primary-black;
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
    box-sizing: border-box;
  }

  &:active:focus {
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
  }
}

.button-with-add-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 24px;
  margin: 20px 0px;
  width: 100%;
  height: 54px;
  background-color: #FFFFFF;
  color: $primary-black;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 1px 0px rgba(0, 0, 0, 0.03);
  border: 0px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  .icon {
    margin-right: 1rem;

    path {
      fill: $primary-black;
    }

    rect {
      fill: $primary-light-yellow;
    }
  }

  &:hover, &:active, &:focus {
    background-color: #FFFFFF;
    color: $primary-black;
  }

  &:focus {
    padding: 14px 22px;
    border: 2px solid $primary-black;
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
    box-sizing: border-box;
  }

  &:active:focus {
    box-shadow: 0px 0px 0px 3px $primary-light-yellow;
  }
}

.customGroupInput {
  .input-group-text {
    background-color: transparent;
    margin-left: -1px !important;
  }

  &.input-with-suffix {
    .form-control {
      border-right: none !important;
    }
  }

  &.input-with-prefix {
    .form-control {
      border-left: none !important;
    }
  }

}

.file-input-button {
  position: absolute !important;
  height: 0;
  width: 0;
  border: 0;
  padding: 0;
  visibility: hidden;
}

.image-thumbnail {
  height: 64px;
  width: 96px;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;

  &.is-invalid {
    border: 2px solid $primary-red;
  }

  &.big {
    width: 124px;
    height: 90px;
  }

  &.small {
    width: 44px;
    height: 36px;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);
}

.file-upload-input {
  position: relative;

  label {
    margin-left: 16px !important;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

/*-------------color-picker-------------*/

.custom-radio-wrapper {
  .custom-radio {
    position: absolute;
    height: auto;
    width: auto;
    margin: 0;
    border: 0 !important;
    visibility: hidden;
  }
}

.color-picker {
  .list-group {
    flex-direction: row;
    flex-wrap: wrap;

    .list-group-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1 0 20%;
      background: #FFFFFF;
      box-sizing: border-box;
      border: 1px solid #D9D9D9;
      border-radius: 0 !important;

      .form-check {
        width: 100%;
        position: relative;

        input:focus + .form-check-label .color-radio {
          border-radius: 6px;
          box-shadow: inset 0px 0px 0px 2px $primary-light-yellow, inset 0px 0px 0px 3px #FFFFFF;
        }

        input:checked + .form-check-label .color-radio {
          background-color: $primary-text-black;
          color: #FFFFFF;

          .color-circle {
            border: 2px solid #FFFFFF;
          }
        }

        input:checked:hover + .form-check-label .color-radio {
          background-color: $primary-text-black;
          color: #FFFFFF;

          .color-circle {
            border: 2px solid #FFFFFF;
          }
        }

        input:hover + .form-check-label .color-radio {
          background-color: #F2F2F2;
        }
      }

      .form-check-label {
        padding: 8px;
      }
    }
  }
}

.color-radio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  color: $primary-text-black;
  border-radius: 6px;

  .color-circle {
    height: 22px;
    width: 22px;
    border-radius: 15px;
    display: inline-block;

    &.white-circle {
      border: 2px solid rgba(0, 0, 0, 0.5);
    }
  }
}

/*--------------------*/

.firstYesNo {
  div {
    border-top: none;
    padding-top: 0;
  }
}

.btn-close:focus {
  box-shadow: inset 0px 0px 0px 2px $primary-light-yellow,
}

.card {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 1px 0px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  border: 0;
}

.Toastify__toast-container {
  margin-top: 30px;
}

.Toastify__toast {
  padding: 16px !important;
  margin-bottom: 10px;

  .Toastify__toast-body {
    padding: 0;
    margin: 0;

    > div {
      display: flex;
    }
  }

  .toast-body {
    color: white;
  }

  span {
    display: inline-block;
    vertical-align: top;
  }

  svg {
    width: 40px;
    margin-right: 16px;
  }

  &.toastify-success {
    background-color: #F5FFF2;
    border: 2px solid #00DD42;
    border-radius: 8px;
    font-weight: 700;
    font-size: 17px;
    color: #00330F;
  }

  &.toastify-danger {
    background: #FEE2E2;
    border: 2px solid #FCA5A5;
    border-radius: 8px;
    font-weight: 700;
    font-size: 17px;
    color: #330000;
  }

  &.toastify-warning {
    background: #FEF3C7;
    border: 2px solid #FCD34D;
    border-radius: 8px;
    font-weight: 700;
    font-size: 17px;
    color: #332800;
  }
}

/*---------Modals-----------*/
.modal {
  .custom-select__control {
    transition: none;
  }
}

.auth-lead-modal {
  .modal-content {
    overflow: hidden;
  }
}

.addEquipmentModal {
  .modal-footer {
    border-top: 1px solid #E6E6E6 !important;
  }

  .attached-equipments {
    display: flex;
    flex-direction: column;

    .attached-equipments-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 5px 15px;
      background-color: $secondary-light-color;
      border-top: 1px solid #E6E6E6;
      border-bottom: 1px solid #E6E6E6;
      color: #4D4D4D
    }

    .attached-equipments-list {
      display: flex;
      flex-direction: column;
      max-height: 245px;
      overflow-y: scroll;
      margin-bottom: 4px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f5f6f7;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .attached-equipments-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 27px;

      &:not(:last-child) {
        border-bottom: 1px solid #F2F2F2;
      }

      .standardIconButton {
        height: 32px;
        width: 32px;
        padding: 8px;
      }
    }
  }
}

.equipment-diff-modal {
  .form-check-input {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }

  .modal-footer {
    .destructiveButton {
      background: #181818;
      color: #FFFFFF;
    }

    @media (min-width: 576px) {
      justify-content: flex-start !important;
      button {
        width: 25% !important;
      }
    }
  }
}

.listing-detail-photos-modal {
  .modal-dialog {
    margin: 0px;
    max-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
  }

  .modal-content {
    border-radius: 0px !important;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    border: 0px;

    .modal-header {
      position: relative;
      padding: 0px !important;
      margin: -1px -1px 0px -1px;
      @media (max-width: 767px) {
        height: 48px;
      }
      height: 84px;

      .modal-title {
        height: 100%;
        max-width: 100%;
      }

      &::after {
        content: "";
        position: absolute;
        top: 64px;
        left: 0;
        height: 20px;
        width: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background: #FFFFFF;
      }

      @media (max-width: 767px) {
        &::after {
          all: unset;
        }
      }
    }
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    background: rgba(0, 0, 0, 0.8);
    color: #FFFFFF;
    font-size: 16px !important;
    font-weight: normal !important;
    height: 100%;
    padding: 0px 48px;

    .heading-ymm {
      font-size: inherit;
      font-weight: inherit;
      margin-bottom: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    .close {
      display: flex;
      align-items: center;
      font-size: 16px !important;
      font-weight: normal !important;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        margin-bottom: 0;
      }

      .close-label {
        display: flex;
        align-items: center;
        margin-right: 8px;
        @media (max-width: 768px) {
          display: none;
        }
      }

      .close-btn {
        cursor: pointer;
      }
    }
  }
}

.modalDeleteRed {
  .modal-footer {
    button:last-child {
      background-color: #CC0000;
      border-color: #CC0000;
      color: #FFF;
    }
  }
}

.custom-select__menu {
  z-index: 1000 !important;
}

.equipment-modal-selected {
  background: rgba(249, 220, 75, 0.2) !important;
  border: 4px solid #F9DC4B !important;
  border-radius: 8px;
  padding: 0px !important;
}

.equipment-modal-selected-overlay {
  position: absolute !important;
  width: calc(100% + 2px) !important;
  height: calc(100% + 2px) !important;
  top: -1px;
  left: -1px;
  right: 0;
  bottom: 0;
  background: rgba(249, 220, 75, 0.2) !important;
  border: 4px solid #F9DC4B !important;
  border-radius: 8px;
  padding: 0px !important;
  z-index: 2;
}

.location-modal {
  .location {
    margin-left: 4px;
    @media (max-width: 575px) {
      display: none;
    }
  }

  .modal-footer {
    .destructiveButton {
      background: #181818;
      color: #FFFFFF;
    }

    @media (min-width: 576px) {
      justify-content: flex-start !important;
      button {
        width: 25% !important;
      }
    }
  }
}

.notification-settings-modal {
  .modal-content {
    .checkAll {
      right: 0 !important;
      @media (max-width: 375px) {
        right: -6px !important;
      }
    }
    .modal-footer {
      border-top: 1px solid #E5E5E5;

      button {
        background-color: #181818;
        color: #FFFFFF;
        flex: none;
        width: 50% !important;
      }
    }
  }
}

.reply-to-offer-modal {
  .modal-body {
    .form-check {
      height: 48px;
      background: #FFFFFF;
      border: 1px solid #D4D4D8;
      border-radius: 6px;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &:last-child {
        margin-bottom: 24px;
      }

      .form-check-input {
        margin-top: 12px;
      }

      .form-check-label {
        line-height: 100%;
        padding: 14px 16px 14px 40px;
      }
    }
  }

  .action-btns {
    gap: 4px;
    display: flex;

    button {
      width: 50%;
    }
  }
}

.vin-scanner-modal {
  .modal-body {
    > div {
      margin: 0px !important;
      padding: 0px 16px 16px;
    }

    li {
      margin: 0px;
      padding: 16px;
    }

    li:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    form {
      .d-grid {
        margin-bottom: 0px !important;
      }

      .upperCase > :first-child {
        margin-bottom: 16px !important;
      }
    }
  }

  .scanArea {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    overflow: hidden;

    background: #FAFAFA;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
  }

  .upperCase {
    .input-group-text {
      width: 56px !important;
    }
  }

  .loading-wrapper {
    position: relative;

    > :first-child {
      margin-bottom: 72px;
    }

    .loading-text {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 40px;
    }
  }

  .found-vehicle {
    gap: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background: #FAFAFA;
    border: 1px solid #D4D4D8;
    box-sizing: border-box;
    border-radius: 6px;

    .vehicle-ymm {
      color: $primary-black;
      font-weight: 600;
    }
  }
}

.holidaysModal {
  .modal-header {
    border-bottom: 1px solid #E4E4E7 !important;

    .helper-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #3F3F46;
    }
  }

  .holiday-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    min-height: 77px;

    &:not(:last-child) {
      border-bottom: 1px solid #E4E4E7;
    }

    input {
      margin-left: 0px;
    }
  }
}

.inviteStaffMemberModal {
  .modal-header {
    border-bottom: 1px solid #E4E4E7 !important;
  }
}

.changeRoleModal {
  .modal-header {
    border-bottom: 1px solid #E4E4E7 !important;
  }

  .roles-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid #E4E4E7;
    }
  }
}

.share-modal {
  .react-share__ShareButton {
    border-radius: 50px;
    overflow: hidden;

    svg {
      height: 48px;
      width: 48px;
    }
  }

  .share-url {
    background: #F4F4F5;
    padding: 17px 24px;
    border-radius: 8px;
    border: 1px solid #E4E4E7;

    .url-text {
      width: calc(100% - 85px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .share-buttons {
    background: #F4F4F5;
    padding: 24px 0px;
    border-radius: 8px;
    border: 1px solid #E4E4E7;
    position: relative;

    hr {
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: #E4E4E7;
      top: 0;
      left: 50%;
      margin: 0;
      opacity: 1;
    }
  }
}

.browse-features-modal {
  .modal-header {
    border-bottom: 1px solid #E6E6E6 !important;
  }

  .features-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (max-width: 549px) {
      flex-direction: column;
    }
  }

  .features-group {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 50%;
    @media (max-width: 549px) {
      width: 100%;
    }
  }
}


.make-offer-modal {
  .modal-header {
    padding-bottom: 32px;
  }

  .modal-body {
    .purchase-form-heading {
      margin-bottom: 4px;
    }

    .helper-text {
      color: #4D4D4D;
      font-size: 15px;
      font-weight: 400;
    }

    .vehicle-data-ymmt {
      color: #181818;
      font-weight: 600;
    }

    .purchase-form {
      .form-check-input {
        margin: 16px 0 0 16px;
      }

      .form-check-label {
        padding-top: 12px;
        padding-left: 48px;
        padding-bottom: 12px;
        padding-right: 16px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 6px;

        .range-slider {
          margin-top: 18px;
          margin-left: -32px;

          .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: #222222;
          }

          > div {
            margin-bottom: 0px !important;

            > div:last-child {
              margin-bottom: 0px !important;
            }
          }
        }

        .check-label-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          font-weight: 600;
          font-size: 18px;
          color: #000000;
        }
      }

      .form-check-input:checked + .form-check-label {
        background: #EFF6FF;
        border: 1px solid #BFDBFE;;
      }
    }

    .summary-body {
      border: 1px solid #ECECEC;
      box-shadow: none;
    }
  }
}

.financing-modal {
  .modal-header {
    background-color: #ECECEC!important;
    padding: 0!important;
    padding-bottom: 5px!important;
  }
  .modal-content {
    background-color: #ECECEC!important;
    padding: 15px!important;
    transform: scale(0.95);
  }
  .title {
    display: none!important;
  }
}

.payout-record-modal {
  .modal-header {
    border-bottom: 1px solid #E4E4E7 !important;
  }

  .modal-body {
    .signature-section {
      ul {
        display: flex;
        border-top: 1px solid #E4E4E7;
        border-bottom: 1px solid #E4E4E7;

        img {
          max-width: 150px;
        }

        @media (max-width: 500px) {
          flex-direction: column;
        }

        li {
          flex: 1;
          padding: 16px;
          justify-content: space-between;
          display: flex;
          flex-direction: column;

          > div:last-child {
            flex-direction: column !important;
            align-items: flex-start !important;
          }

          &:not(:last-child) {
            border-right: 1px solid rgb(240, 241, 242);
            @media (max-width: 500px) {
              border-bottom: 1px solid rgb(240, 241, 242);
            }
          }
        }
      }
    }
  }
}

.appointments-history-modal {
  .modal-content {
    overflow: hidden;
  }

  .modal-header {
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%), 0px 1px 0px rgb(0 0 0 / 3%);
  }
}

.sold-to-dealership-modal {
  div[data-testid="vehicle-vin-validate"] {
    margin: 0 !important;
    min-height: 100px;
  }

  .proof-of-sale-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 16px;

    .proof-of-sale-list-item {
      flex: 1;
      min-width: 204px;
      padding: 16px 0;

      .proof-of-sale-item {
        display: block;
        height: 48px;
        min-width: 48px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }

      img {
        object-fit: cover;
        max-width: 48px;
        max-height: 100%;
      }
    }
  }

  .scanArea {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    overflow: hidden;
    background: #FAFAFA;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    margin-bottom: 24px;
  }

  .found-vehicle {
    gap: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background: #FAFAFA;
    border: 1px solid #D4D4D8;
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom: 16px;

    .vehicle-ymm {
      color: $primary-black;
      font-weight: 600;
    }
  }
}

/*--------------------*/

/*---------Offcanvas-----------*/
.offcanvas-backdrop {
  height: 100vh !important;
}

.marketplace-offcanvas {
  height: fit-content !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .offcanvas-body {
    padding: 0px;

    .accordion-item:last-child {
      .accordion-button {
        border-bottom: none;
      }
    }

    .accordion-button,
    .accordion-collapse {
      background-color: #FFFFFF !important
    }

    > * {
      background-color: #FFFFFF !important
    }
  ;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .title {
      padding: 15px 19px;
    }

    .accordion {
      padding: 0px 15px;
    }
  }
}

.route-steps-menu-offcanvas {
  height: fit-content !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .offcanvas-header {
    justify-content: flex-end;
    border-bottom: 1px solid #D9D9D9;

    .mobile-close {
      text-decoration: none;
    }
  }

  .offcanvas-body {
    ul li {
      max-width: 100%;
    }

    aside {
      margin-right: 0px !important;
    }
  }
}

.vehicles-list-offcanvas {
  height: fit-content !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .offcanvas-header {
    justify-content: flex-end;
    border-bottom: 1px solid #D9D9D9;

    .mobile-close {
      text-decoration: none;
    }
  }

  .offcanvas-body {
    .filters-list {
      margin-top: 0px;

      li {
        max-width: 100%;
      }
    }
  }
}

.pac-container {
  z-index: 10000;
}

.header-nav-offcanvas {
  height: fit-content !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .offcanvas-header {
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;

    .mobile-close {
      text-decoration: none;
    }
  }

  &.consumer-header {
    background-color: #181818;
    z-index: 9999;
    padding: 44px 24px;
    height: 100vh;
    border: 0;
    border-radius: 0;

    .offcanvas-header {
      border: none;
    }

    .offcanvas-body {
      p {
        color: $white;
      }

      .navi-link {
        font-weight: 800;
        font-size: 28px;
        padding: 5px 0;
        color: $white;

        &.small {
          font-weight: 400;
          font-size: 16px;
          height: 28px;

          &.active-nav {
            color: $yellow;
            font-weight: 400;
            background-color: transparent;
          }
        }

        &.active-nav {
          color: $yellow;
          font-weight: 800;
          background-color: transparent;
        }

        &:hover {
          font-weight: 800;
          background-color: transparent;
        }
      }
    }
  }

  .offcanvas-body {
    .navi-link {
      width: 100%;
      text-decoration: none;
      height: 48px;
      padding: 0 16px;
      border-radius: 8px;
      margin-bottom: 2px;
      cursor: pointer;
      color: $primary-text-black;

      &.active-nav {
        background-color: #3F3F46;
        color: #FFFFFF;
        font-weight: 600;
      }

      &:hover {
        background-color: #3F3F46;
        color: #FFFFFF;
        font-weight: 600;
      }
    }
  }
}

/*-----------------------------*/

/*---------Tooltip-----------*/
.marketplace-tooltip {
  &.tooltip-inner {
    @media (min-width: 768px) {
      max-width: 500px;
    }
  }
}

/*-----------------------------*/

.group-separator {
  display: flex;
  align-items: center;
  position: relative;
  height: 16px;
  width: 100%;
  margin-bottom: 8px;
  font-size: 13px;
  color: #555555;

  &:before {
    margin: 0 auto;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    border-top: 1px solid rgba(102, 102, 102, 0.1);
  }

  > span {
    margin: 0px 8px;
    padding: 0px 10px;
    background-color: #ececec;
    z-index: 10;
  }
}

.focusable-span {
  border-radius: 4px;
}

#dropdown-item-button-cancelation + .dropdown-menu.show {
  bottom: 40px;
  right: 0;
}

#dropdown-item-button-appointment-actions + .dropdown-menu.show {
  bottom: 40px;
  right: 0;
}

.dropup .dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  &:active {
    background-color: rgba(255, 255, 255, 0.15) !important;
  }
}

.dropdown-menu-dark {
  > button > span {
    svg path {
      fill: #FFFFFF;
    }
  }
}

.page-header-wavy {
  display: inline;
  width: fit-content;
  font-weight: 800;
  font-size: 32px;
  line-height: 130%;
  color: $primary-black;
  background-image: url('../public/assets/img/underline-segment.png');
  background-size: 2em 0.5em;
  background-repeat: repeat-x;
  background-position: 0 100%;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

.mobile-menu-btn-wrapper {
  border-bottom: 1px solid #D9D9D9;
  border-top: 1px solid #D9D9D9;

  .mobile-menu-btn {
    justify-content: space-between;
    width: 100%;
    margin: 16px 0px;
    box-sizing: border-box;
  }
}

.e-timepicker {
  background-color: #00DD42;
}

.buy-now-button {
  border: 1px solid #D9D9D9;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.03);
  border-radius: 36px !important;
  text-transform: uppercase;
  text-decoration: none;
}

.stripe-pink {
  min-height: 48px;
  border: 1px solid #FDA4AF;
  border-left: none;
  border-right: none;
  position: relative;
  background: repeating-linear-gradient(
                  -55deg,
                  #FECDD3,
                  #FECDD3 15px,
                  #FDA4AF 15px,
                  #FDA4AF 30px
  );

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.15);
  }

  > div {
    padding: 4px 15px;
    border-radius: 40px;
    background: #fdb2bb;
    color: #881337;
    font-size: 15px;
    position: relative;
    z-index: 100;

    > span {
      margin-right: 10px;
    }
  }
}

.photo-details {
  position: absolute;
  height: 30%;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .70) 48.72%);
  color: white;
  font-size: 15px;
  line-height: 18px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;

  path {
    fill: #FFF;
  }

  .dropdown-toggle {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;
    margin-right: 3px;
    width: 32px;
    height: 32px;
    border: 3px solid transparent;

    &::after {
      content: none;
    }

    &:focus {
      box-shadow: none;
      border: 3px solid $primary-light-yellow;
      border-radius: 11px;
    }

    span {
      padding-bottom: 3px;
    }
  }

  .dropdown-menu {
    &.show {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 4px 0px;
      width: 212px;
      background: $primary-text-black;
      border-radius: 6px;
    }

    .dropdown-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #FFF;

      &:focus,
      &:hover,
      &:active {
        background: #595959;
      }
    }
  }

  .photo-details-text {
    margin-right: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px 8px;
    text-decoration: underline;
    padding-bottom: 10px;
  }
}

.photo-item {
  min-height: 170px;
  max-height: 170px;
  user-select: none;
  pointer-events: all;

  &.draggable {
    .dropzone-container {
      cursor: grabbing;
    }
  }
}

.hide-in-mobile {
  @media (max-width: 575px) {
    display: none !important;
  }
}

.modal {
  padding-left: 0 !important;
}

#billing-addons-tooltip .tooltip-inner {
  @media (min-width: 768px) {
    max-width: 370px;
  }
}
